export class EscKeyPressed {
    static Init(element: any, dotnetHelper: any): void {
        var f = (event: KeyboardEvent) => {
            if (event.key === 'Escape'){
                if (dotnetHelper){
                    dotnetHelper.invokeMethodAsync("InvokeEscKeyPressed");
                }
            }
        };
        element.EscKeyPressed = f;
        document.addEventListener('keydown', f);
    }
    static Remove(element: any): void {
        if (element && element.EscKeyPressed){
            document.removeEventListener("keydown", element.EscKeyPressed);
            delete element.EscKeyPressed;
        }
    }
}
