import { Anchor } from "./Anchor";
import { ClickAway } from "./ClickAway";
import { Focusable } from "./Focusable";
import { ClickIn } from "./ClickIn";
import { EscKeyPressed } from "./EscKeyPressed";
import { BrowserLanguage } from "./BrowserLanguage";
import { Brotli } from "./Brotli";
import { AuthenticationService } from "./AuthenticationService";
import { TinyMCEWrapper } from "./TinyMCEWrapper";
import { InputMask } from "./InputMask";
import { Downloader } from "./Downloader";
import { DropZoneWrapper } from './DropZoneWrapper';
//  /* Default icons are required for TinyMCE 5.3 or above */
//  import 'tinymce/icons/default';

//  /* A theme is also required */
//  import 'tinymce/themes/silver';

//  /* Import the skin */
//  import 'tinymce/skins/ui/oxide/skin.css';

//  /* Import plugins */
//  import 'tinymce/plugins/advlist';
//  import 'tinymce/plugins/code';
//  import 'tinymce/plugins/emoticons';
//  import 'tinymce/plugins/emoticons/js/emojis';
//  import 'tinymce/plugins/link';
//  import 'tinymce/plugins/lists';
//  import 'tinymce/plugins/table';
 
//  /* Import content css */
//  import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
//  import contentCss from 'tinymce/skins/content/default/content.css';

var authenticationService = AuthenticationService;
authenticationService._infrastructureKey = 'Microsoft.AspNetCore.Components.WebAssembly.Authentication';
authenticationService._pendingOperations = {};
authenticationService.handleCallback();
window.AuthenticationService = authenticationService;


window.Anchor = Anchor;
window.BrowserLanguage = BrowserLanguage;
window.Focusable = Focusable;
window.ClickAway = ClickAway;
window.ClickIn = ClickIn;
window.EscKeyPressed = EscKeyPressed;
window.TinyMCEWrapper = TinyMCEWrapper;
window.InputMask = InputMask;
window.Downloader = Downloader;
window.DropZoneWrapper = DropZoneWrapper;

Brotli.Initialize();