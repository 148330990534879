export class Focusable {
    static SetFocus(baseElement: any, jump: number, avoidElement?: any): void {
        const focusableElements = document.querySelectorAll('input, select, textarea, button, [href], [tabindex]:not([tabindex="-1"])');
        const baseIndex = Array.from(focusableElements).indexOf(baseElement);
        const avoidedIndex = Array.from(focusableElements).indexOf(avoidElement);
        var jumpIndex = baseIndex + jump;
        if (jumpIndex < 0)
        {
            jumpIndex += focusableElements.length;
        }
        jumpIndex = jumpIndex % focusableElements.length;
        if (jumpIndex != avoidedIndex)
        {
            (focusableElements[jumpIndex] as HTMLElement).focus();
        }
    }
    static SetFocusNext(element: any, avoidElement?: any): void {
        this.SetFocus(element, 1, avoidElement);
    }
    static SetFocusPrevious(element: any, avoidElement?: any): void {
        this.SetFocus(element, -1, avoidElement);
    }
}
