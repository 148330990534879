import { UserManager } from "./UserManager";

var AccessTokenResultStatus: any;
var AuthenticationResultStatus: any;
var IdTokenResultStatus: any;

(function (AccessTokenResultStatus) {
    AccessTokenResultStatus["Success"] = "success";
    AccessTokenResultStatus["RequiresRedirect"] = "requiresRedirect";
})(AccessTokenResultStatus = exports.AccessTokenResultStatus || (exports.AccessTokenResultStatus = {}));

(function (AuthenticationResultStatus) {
    AuthenticationResultStatus["Redirect"] = "redirect";
    AuthenticationResultStatus["Success"] = "success";
    AuthenticationResultStatus["Failure"] = "failure";
    AuthenticationResultStatus["OperationCompleted"] = "operationCompleted";
})(AuthenticationResultStatus = exports.AuthenticationResultStatus || (exports.AuthenticationResultStatus = {}));

(function (IdTokenResultStatus) {
    IdTokenResultStatus["Success"] = "success";
    IdTokenResultStatus["RequiresRedirect"] = "requiresRedirect";
})(IdTokenResultStatus = exports.IdTokenResultStatus || (exports.IdTokenResultStatus = {}));

(function (AuthenticationResultStatus) {
    AuthenticationResultStatus["Redirect"] = "redirect";
    AuthenticationResultStatus["Success"] = "success";
    AuthenticationResultStatus["Failure"] = "failure";
    AuthenticationResultStatus["OperationCompleted"] = "operationCompleted";
})(AuthenticationResultStatus = exports.AuthenticationResultStatus || (exports.AuthenticationResultStatus = {}));
;

export class OidcAuthorizeService {
    private _userManager: UserManager;

    constructor(userManager: UserManager) {
        this._userManager = userManager;
    }
    async getUser() {
        const user = await this._userManager.getUser();
        return user && user.profile;
    }
    async getAccessToken(request: { scopes: any; }) {
        const user = await this._userManager.getUser();
        if (hasValidAccessToken(user) && hasAllScopes(request, user.scopes)) {
            return {
                status: AccessTokenResultStatus.Success,
                token: {
                    grantedScopes: user.scopes,
                    expires: getExpiration(user.expires_in),
                    value: user.access_token
                }
            };
        }
        else {
            return {
                status: AccessTokenResultStatus.RequiresRedirect
            };
        }
        function hasValidAccessToken(user: any) {
            return !!(user && user.access_token && !user.expired && user.scopes);
        }
        function getExpiration(expiresIn: number) {
            const now = new Date();
            now.setTime(now.getTime() + expiresIn * 1000);
            return now;
        }
        function hasAllScopes(request: { scopes: any; }, currentScopes: Iterable<unknown>) {
            const set = new Set(currentScopes);
            if (request && request.scopes) {
                for (const current of request.scopes) {
                    if (!set.has(current)) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    async getIdToken(request: any) {
        const user = await this._userManager.getUser();
        if (hasValidIdToken(user) && hasAllScopes(request, user.scopes)) {
            return {
                status: IdTokenResultStatus.Success,
                token: {
                    grantedScopes: user.scopes,
                    expires: getExpiration(user.expires_in),
                    value: user.id_token,
                }
            };
        }
        else {
            return {
                status: IdTokenResultStatus.RequiresRedirect
            };
        }
        function hasValidIdToken(user: { id_token: any; expired: any; scopes: any; }) {
            return !!(user && user.id_token && !user.expired && user.scopes);
        }
        function getExpiration(expiresIn: number) {
            const now = new Date();
            now.setTime(now.getTime() + expiresIn * 1000);
            return now;
        }
        function hasAllScopes(request: { scopes: any; }, currentScopes: Iterable<unknown>) {
            const set = new Set(currentScopes);
            if (request && request.scopes) {
                for (const current of request.scopes) {
                    if (!set.has(current)) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    async signIn(state: any) {
        try {
            await this._userManager.clearStaleState();
            await this._userManager.signinRedirect(this.createArguments(state));
            return this.redirect();
        }
        catch (redirectError) {
            return this.error(this.getExceptionMessage(redirectError));
        }
    }
    async completeSignIn(url: any) {
        const requiresLogin = await this.loginRequired(url);
        const stateExists = await this.stateExists(url);
        try {
            const user = await this._userManager.signinCallback(url);
            if (window.self !== window.top) {
                return this.operationCompleted();
            }
            else {
                return this.success(user && user.state);
            }
        }
        catch (error) {
            if (requiresLogin || window.self !== window.top || !stateExists) {
                return this.operationCompleted();
            }
            return this.error('There was an error signing in.');
        }
    }
    async signOut(state: any) {
        try {
            if (!(await this._userManager.metadataService.getEndSessionEndpoint())) {
                await this._userManager.removeUser();
                return this.success(state);
            }
            await this._userManager.signoutRedirect(this.createArguments(state));
            return this.redirect();
        }
        catch (redirectSignOutError) {
            return this.error(this.getExceptionMessage(redirectSignOutError));
        }
    }
    async completeSignOut(url: any) {
        try {
            if (await this.stateExists(url)) {
                const response = await this._userManager.signoutCallback(url);
                return this.success(response && response.state);
            }
            else {
                return this.operationCompleted();
            }
        }
        catch (error) {
            return this.error(this.getExceptionMessage(error));
        }
    }
    getExceptionMessage(error: { error_description: any; message: any; toString: () => any; }) {
        if (isOidcError(error)) {
            return error.error_description;
        }
        else if (isRegularError(error)) {
            return error.message;
        }
        else {
            return error.toString();
        }
        function isOidcError(error: { error_description: any; message?: any; toString?: () => any; }) {
            return error && error.error_description;
        }
        function isRegularError(error: { error_description?: any; message: any; toString?: () => any; }) {
            return error && error.message;
        }
    }
    async stateExists(url: string | URL) {
        const stateParam = new URLSearchParams(new URL(url).search).get('state');
        if (stateParam && this._userManager.settings.stateStore) {
            return await this._userManager.settings.stateStore.get(stateParam);
        }
        else {
            return undefined;
        }
    }
    async loginRequired(url: string | URL) {
        const errorParameter = new URLSearchParams(new URL(url).search).get('error');
        if (errorParameter && this._userManager.settings.stateStore) {
            const error = await this._userManager.settings.stateStore.get(errorParameter);
            return error === 'login_required';
        }
        else {
            return false;
        }
    }
    createArguments(state: any) {
        return { useReplaceToNavigate: true, data: state };
    }
    error(message: any) {
        return { status: AuthenticationResultStatus.Failure, errorMessage: message };
    }
    success(state: any) {
        return { status: AuthenticationResultStatus.Success, state };
    }
    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }
    operationCompleted() {
        return { status: AuthenticationResultStatus.OperationCompleted };
    }
}
