export class ClickAway {
    static InitTwo(element: any, linkElement: any, dotnetHelper: any): void {
        var f = (event: MouseEvent) => {
            if (element && !element.contains(event.target)) {
                if (linkElement && !linkElement.contains(event.target)) {
                    if (dotnetHelper) {
                        dotnetHelper.invokeMethodAsync("InvokeClickOutside");
                    }
                }
            } 
        };
        setTimeout(()=>{
            if (element && !element.ClickAway){
                element.ClickAway = f;
                window.addEventListener("click", f);
            }
        }, 500);
    }
    static InitOne(element: any, dotnetHelper: any): void {
        var f = (event: MouseEvent) => {
            if (element && !element.contains(event.target)) {
                if (dotnetHelper) {
                    dotnetHelper.invokeMethodAsync("InvokeClickOutside");
                }
            } 
        };
        setTimeout(()=>{
            if (element && !element.ClickAway){
                element.ClickAway = f;
                window.addEventListener("click", f);
            }
        }, 500);
    }
    static Remove(element: any): void {
        if (element && element.ClickAway){
            window.removeEventListener("click", element.ClickAway);
            delete element.ClickAway;
        }
    }
}
