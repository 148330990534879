export class DropZoneWrapper {
    element: any;
    inputFile: any;

    static Init(element: any, inputFile: any): void {
        var wrapper = new DropZoneWrapper();
        wrapper.element = element;
        wrapper.inputFile = inputFile;

        wrapper.element.addEventListener('paste', wrapper.PasteHandler);
        wrapper.element.addEventListener("dragenter", wrapper.AddDragClass);
        wrapper.element.addEventListener("dragover", wrapper.AddDragClass);
        wrapper.element.addEventListener("dragleave", wrapper.RemoveDragClass);
        wrapper.element.addEventListener("drop", wrapper.OnDrop);

        element.dropzone = wrapper;
    }
    
    static Remove(element: any): void {
        var wrapper : DropZoneWrapper;
        wrapper = element.dropzone;
        element.removeEventListener('paste', wrapper.PasteHandler);
        element.removeEventListener('dragenter', wrapper.AddDragClass);
        element.removeEventListener('dragover', wrapper.AddDragClass);
        element.removeEventListener('dragleave', wrapper.RemoveDragClass);
        element.removeEventListener("drop", wrapper.OnDrop);
    }

    PasteHandler(e: any) {
        e.currentTarget.dropzone.inputFile.files = e.clipboardData.files;
        const event = new Event('change', { bubbles: true });
        e.currentTarget.dropzone.inputFile.dispatchEvent(event);
    }

    OnDrop(e: any) {
        e.preventDefault();
        e.currentTarget.classList.remove("dragging");

        e.currentTarget.dropzone.inputFile.files = e.dataTransfer.files;
        const event = new Event('change', { bubbles: true });
        e.currentTarget.dropzone.inputFile.dispatchEvent(event);
    }

    AddDragClass(e: any) {
        e.preventDefault();
        e.currentTarget.classList.add("dragging");
    }

    RemoveDragClass(e: any) {
        e.preventDefault();
        e.currentTarget.classList.remove("dragging");
    }
}
